import { Component } from 'preact';
import { log, capture } from './errorHandling';

/**
 * Simple component that patches the event system to make it compatible with Pagesense
 * which is loaded through Google Tag Manager
 */
class PagesensePatch extends Component {
    componentDidMount() {
        this.patchEventSystem();
    }

    patchEventSystem = () => {
        // Skip if already patched
        if (window._eventSystemPatched) {
            return;
        }

        try {
            // Store original method
            const originalAddEventListener = EventTarget.prototype.addEventListener;

            // Replace with patched version
            EventTarget.prototype.addEventListener = function(type, listener, options) {
                // Skip patching if null or undefined listener
                if (!listener) {
                    return originalAddEventListener.call(this, type, listener, options);
                }

                // Create a wrapped listener
                const wrappedListener = function(event) {
                    try {
                        // Add missing methods that Pagesense expects
                        if (event && !event.isImmediatePropagationStopped) {
                            event.isImmediatePropagationStopped = () => false;
                        }

                        // Call original listener
                        return listener.apply(this, arguments);
                    } catch (err) {
                        log(err);
                        capture(err);
                        return false;
                    }
                };

                // Apply the original method with our wrapped listener
                return originalAddEventListener.call(this, type, wrappedListener, options);
            };

            // Mark as patched to avoid double patching
            window._eventSystemPatched = true;
            log('Event system patched for Pagesense compatibility');
        } catch (err) {
            log(err);
            capture(err);
        }
    };

    render() {
        // Simply render children without additional DOM elements
        return this.props.children;
    }
}

export default PagesensePatch;
