import 'css/styles.css';
import { h, render } from 'preact';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'js/components/User/AuthProvider.js';
import 'js/shared/logging.js';
import Routes from 'js/routes.js';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './shared/ErrorBoundary.js';
import PagesensePatch from './shared/PagesensePatch';

Sentry.init({
    dsn: 'https://4eff94ae3c7b8db1c7493042b72fd995@o4508406412279808.ingest.us.sentry.io/4508882245124096',
    integrations: [],
    tracesSampleRate: 0.1,
    environment: process.env.NODE_ENV,
});

const App = () => {
    return (
        <ErrorBoundary>
            <PagesensePatch>
                <AuthProvider>
                    <Router>
                        <Routes />
                    </Router>
                </AuthProvider>
            </PagesensePatch>
        </ErrorBoundary>
    );
};

render(<App />, document.getElementById('app'));
