import { h, Component } from 'preact';
import { log, capture } from './errorHandling';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error });
        log(error);
        capture(error);
    }

    render() {
        // If an error was caught, display the fallback UI
        if (this.state.hasError && process.env.NODE_ENV !== 'production') {
            return this.props.fallback || (
                <div>
                    <h2>Something went wrong.</h2>
                    {process.env.NODE_ENV !== 'production' && (
                        <details>
                            <summary>Error details</summary>
                            <pre>{this.state.error && this.state.error.toString()}</pre>
                        </details>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
