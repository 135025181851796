import * as Sentry from '@sentry/react';

export function capture(error, contextInfo = {}) {
    Sentry.withScope(scope => {
        // Add any provided context info
        Object.entries(contextInfo).forEach(([key, value]) => {
            scope.setExtra(key, value);
        });

        // Capture the exception
        Sentry.captureException(error);
    });
}

export function log(message, force = false) {
    if (process.env.NODE_ENV !== 'production' || force) {
        console.log(message);
    }
}
